import { FC, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Form, Row } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';
import { useGuestAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { CardInfo } from '../../../modules/interfaces';

type QuestionModalProps = {
  show: boolean;
  onConfirm: () => void;
  onHide: () => void;
  customerId: string;
};

export interface DefaultPaymentMethodParams {
  params?: { customerId: string };
  authToken?: string;
}

const PayPackagesQuestionModal: FC<QuestionModalProps> = ({
  show,
  onConfirm,
  onHide,
  customerId,
}) => {
  const { token } = useGuestAuth()!;

  const url = endpoints.GET_DEFAULT_PAYMENT_METHOD;
  const requestOpts = useMemo<DefaultPaymentMethodParams>(() => {
    return {
      authGuestToken: token,
      params: { customerId },
    };
  }, [token, customerId]);

  const [{ data }, getCardInfo] = useRequest<CardInfo>(url, 'get', requestOpts);

  useEffect(() => {
    if (!show) return;

    getCardInfo();
  }, [show]);

  return (
    <Modal
      show={show}
      dialogClassName="post-editor-modal modal-200w"
      centered
      id="guest-portal"
    >
      <Modal.Header>
        <Modal.Title className="editor-title">
          Use default card or use another card for add-ons
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Text>
            Would you like to use the card ending in {data?.result.last4 || '****'} to
            complete this transaction? The provided payment method will be used to settle
            all outstanding transactions.
          </Form.Text>
        </Form>

        <Row
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'center',
            marginTop: 28,
            marginBottom: 28,
          }}
        >
          <Col xs="auto" sm="auto">
            <Button className="save-button  no-border-radius" onClick={onHide}>
              Yes
            </Button>
          </Col>
          <Col xs="auto" sm="auto">
            <Button onClick={onConfirm} className="delete-button no-border-radius">
              Use other card
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PayPackagesQuestionModal;
