import { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import './styles/styles.css';
import { PackagesTable } from '../../Tables/Packages';
import Header from '../../Header';

export const Packages: FC = () => {
  return (
    <Container fluid className="reservation-list-container">
      <Header title="Add-ons" />
      <PackagesTable />
    </Container>
  );
};
