/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useCallback, useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Whatsapp,
  Envelope,
  GeoAlt,
  Wifi,
  Trash3Fill,
  Plus,
} from 'react-bootstrap-icons';
import { useForm, FormProvider } from 'react-hook-form';

import './styles/styles.css';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Badge, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CheckInCard } from '../Cards/CheckInCard';
import { CustomizeStay } from '../Modals/GuestPagesModals';

import { GuestPortalContext } from '../../modules/context/guestPortalContext';
import { useGetAccessCode } from './endpoints/GuestPageEnpoints';
import { useRequest, useGuestAuth, useNotifications } from '../../modules/hooks';
import { PackagesResponse, GuestPackage } from '../../modules/interfaces';
import { endpoints } from '../../modules/mappers/urls';
import { updateOrCreatePackageList } from '../../modules/utils/updateOrCreateList';
import {
  coachHouseCellphone,
  coachHouseContactPhone,
  ourStoryContent,
} from '../../constants/generalData';
import { formatDateTime } from '../../modules/utils/dateFormat';
import { ConfirmModal, ModalState } from '../Modals/Confirm';
import { openSMSApp } from '../../modules/utils/sendMessage';
import { CardForPayPackagesModal } from '../Modals/PackagesModals/CardForPayPackagesModal';
import PayPackagesQuestionModal from '../Modals/PackagesModals/PayPackagesQuestionModal';
import RenderHTMLContent from '../../modules/utils/renderHTMLcontent';

export const GuestPortal: FC = () => {
  const location = useLocation();
  const [customizeStayModal, setCustomizeStayModal] = useState(false);
  const [roomCode, setRoomCode] = useState('');
  const [guestId, setGuestId] = useState('');
  const { token, generateAuthToken } = useGuestAuth()!;
  const { reservationId } = useParams();
  const [packages, setPackages] = useState<PackagesResponse[]>([]);
  const [packageData, setPackageData] = useState<PackagesResponse>();
  const [guestPackages, setGuestPackages] = useState<GuestPackage[]>([]);
  const [showAnotherCardModal, setShowAnotherCardModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const { setSimpleToasts } = useNotifications()!;
  const [confirmModal, setConfirmModal] = useState<ModalState>({
    show: false,
    title: '',
    body: '',
  });

  const methods = useForm<GuestPackage>({
    defaultValues: {
      price: 0,
      quantity: 1,
      reservationId: '',
      packageId: '',
      amount: 0,
      size: '',
    },
    mode: 'onChange',
  });

  const navigate = useNavigate();
  const {
    reservation,
    getMewsReservations,
    preregisteredGuest,
    getGuestPreregistered,
    loading,
    addPackageResult,
    addPackageToGuest,
    addPackageError,
  } = useContext(GuestPortalContext)!;
  const [maxTitleLength, setMaxTitleLength] = useState(55);

  const checkoutURL = `${endpoints.MEWS_RESERVATIONS}/check-out`;
  const [
    { data: checkoutData, loading: loadingCheckout, error: guestError },
    checkOutReservation,
  ] = useRequest(
    checkoutURL,
    'post',
    {
      authGuestToken: token,
    },
    { manual: true },
  );

  const closeConfirmModal = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
  };
  const openConfirmModal = () => {
    setConfirmModal({
      title: 'Confirm Check Out',
      body: (
        <span>
          <b>Property access code will cease to work once Check Out is done</b>. <br />{' '}
          <br /> Are you sure you want to proceed with the Check Out?
        </span>
      ),
      show: true,
    });
  };

  const handleConfirmModal = useCallback(() => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
    checkOutReservation({
      data: {
        reservationId,
      },
    });
  }, [confirmModal, setConfirmModal, checkOutReservation]);

  const [
    { data: accessCodeResult, loading: loadingAccessCode },
    getReservationAccessCode,
  ] = useGetAccessCode(reservationId || '');

  const [{ data, loading: loadingPackages }] = useRequest<PackagesResponse[]>(
    endpoints.PACKAGES,
    'get',
    {
      authGuestToken: token,
    },
  );

  useEffect(() => {
    if (!reservationId) return;

    generateAuthToken({ data: { reservationId } });
  }, [generateAuthToken, reservationId, token]);

  useEffect(() => {
    if (loadingCheckout) return;

    if (checkoutData) {
      getMewsReservations();
    }

    if (guestError && guestError.response) {
      const { message } = guestError.response.data.error;
      if (message.includes('too early') && message.includes('check-out')) {
        setSimpleToasts({
          message:
            'It’s too early to check-out. If you are looking to leave ahead of your scheduled check-out date, please reach out to your personal concierge. Thank you!',
          type: 'warning',
          show: true,
        });
      } else {
        setSimpleToasts({
          message:
            'Whoops! Please refresh the page. If this message reappears after refreshing, please reach out to your personal concierge for further assistance. Thank you!',
          type: 'danger',
          show: true,
        });
      }
    }
  }, [loadingCheckout, checkoutData, guestError]);

  useEffect(() => {
    if (!token) return;
    getGuestPreregistered();
  }, [token]);

  // If a guest has already registered, shows access code or redirect to feedback view
  useEffect(() => {
    if (!preregisteredGuest) return;

    if (reservation?.EndUtc) {
      const now = DateTime.now();
      const reservationEnd = DateTime.fromISO(reservation?.EndUtc || '');

      if (now > reservationEnd || reservation.State === 'Processed') {
        navigate(`/feedback/${reservationId}`, {
          state: { guestEmail: reservation?.Customer?.Email },
        });
        return;
      }
    }

    setGuestId(preregisteredGuest.guest.uuid || '');

    getReservationAccessCode();
  }, [preregisteredGuest, navigate, reservationId, reservation?.EndUtc]);

  // Set room code after guest registration
  useEffect(() => {
    if (loadingAccessCode || !accessCodeResult) return;
    setRoomCode(String(accessCodeResult.result));
  }, [accessCodeResult, getReservationAccessCode, loadingAccessCode]);

  useEffect(() => {
    if (!location?.state?.roomCode) return;

    setRoomCode(location?.state?.roomCode);
  }, [location]);

  useEffect(() => {
    if (loadingPackages || !reservation?.StartUtc) return;

    const validPackages: PackagesResponse[] =
      data?.result.reduce((accumulator: PackagesResponse[], item) => {
        if (!item.params) {
          return [...accumulator, item];
        }

        const advanceNotice = item.params.find(value => value.type === 'ADVANCED NOTICE');

        if (advanceNotice) {
          const startDate = DateTime.fromISO(reservation?.StartUtc);

          const [amount, unit] = advanceNotice.values.split(' ');
          const advancedNoticeDate = startDate.minus({ [unit]: parseInt(amount, 10) });

          const currentDate = DateTime.now();

          if (currentDate < advancedNoticeDate) {
            return [...accumulator, item];
          }
        } else {
          return [...accumulator, item];
        }

        return accumulator;
      }, []) || [];

    setPackages(validPackages);
  }, [data?.result, loadingPackages, reservation?.StartUtc, preregisteredGuest]);

  useEffect(() => {
    if (!methods.getValues('price') || customizeStayModal) return;

    const guestPageList = updateOrCreatePackageList(guestPackages, methods.getValues());

    setGuestPackages(guestPageList);

    methods.reset({
      price: 0,
      quantity: 1,
      reservationId: '',
      packageId: '',
      amount: 0,
      size: '',
    });
  }, [methods, customizeStayModal]);

  const onSubmit = () => {
    addPackageToGuest({
      data: {
        reservationId,
        packages: guestPackages || [],
        guestId,
      },
    });
  };

  useEffect(() => {
    if (!addPackageResult && !addPackageError) return;

    if (addPackageError) {
      setSimpleToasts({
        message: addPackageError.message,
        type: 'danger',
        show: true,
      });
    }

    if (addPackageResult) {
      getGuestPreregistered();
      setGuestPackages([]);

      setSimpleToasts({
        message: 'Add-on requested successfully',
        type: 'success',
        show: true,
      });
    }
  }, [addPackageResult]);

  const showPackageModalHandler = (item: PackagesResponse) => {
    setPackageData(item);

    const updatePackage = guestPackages.find(
      packageItem => packageItem?.packageId === item.uuid,
    );

    if (updatePackage) {
      methods.reset({
        price: updatePackage.price,
        quantity: updatePackage.quantity,
        reservationId: updatePackage.reservationId,
        packageId: updatePackage.packageId,
        amount: updatePackage.amount,
        size: updatePackage?.size || '',
      });
    }

    setCustomizeStayModal(true);
  };

  const deleteGuestPackage = (item: PackagesResponse) => {
    const values = guestPackages.filter(({ packageId }) => packageId !== item.uuid);
    setGuestPackages(values);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 770) {
        setMaxTitleLength(30);
      }
      if (window.innerWidth < 770) {
        setMaxTitleLength(20);
      }
      if (window.innerWidth < 400) {
        setMaxTitleLength(14);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <FormProvider {...methods}>
      <Container className="container-paddings">
        <hr
          style={{
            marginTop: '45px',
          }}
        />
      </Container>
      <Container
        className="container-paddings direction-padding"
        style={{ height: '4.5rem' }}
      >
        <Row style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Col
            xs={7}
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <GeoAlt size={26} />
            <div className="direction-title">
              284 Lafayette St,
              <br /> Salem Ma,
              <br /> 01970
              <br />
            </div>
          </Col>
          <Col
            xs={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Envelope
              onClick={() =>
                openSMSApp(reservation?.Property?.cellphoneNumber || coachHouseCellphone)
              }
              title="Message us"
              size={26}
              className="message-button-icon"
            />

            <Whatsapp
              onClick={e => {
                window.location.href = `tel:${
                  reservation?.Property?.phoneNumber || coachHouseContactPhone
                }`;
                e.preventDefault();
              }}
              title="Call us"
              size={26}
              className="message-button-icon"
              style={{ marginLeft: '30px' }}
            />
          </Col>
        </Row>
      </Container>
      <Container
        className="container-paddings direction-padding"
        style={{ height: '5.5rem', marginTop: '5px' }}
      >
        <Row style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Col className="network-align">
            <Wifi size={30} />
            <div style={{ marginLeft: '15px' }}>
              <div className="network-title">Wifi Network</div>
              <div style={{ fontSize: '14px' }}>
                {loading
                  ? 'Loading password...'
                  : reservation?.Room?.wifiName || 'Wi-Fi Network Not Available'}
              </div>
            </div>
          </Col>
          <Col className="password-align">
            <div>
              <div className="password-title">Password</div>
              <div style={{ fontSize: '14px' }}>
                {loading
                  ? 'Loading password...'
                  : reservation?.Room?.wifiPassword || 'Wi-Fi Password Not Available'}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <div className="table-title ">
            <h6 className="mb-0 warning-title" style={{ color: '#979797' }}>
              Your door code will not become active until your scheduled check-in time
            </h6>
          </div>
        </Row>
      </Container>
      <Container
        style={{
          width: '100%',
          paddingLeft: '0px',
          paddingRight: '0px',
          backgroundColor: 'white',
          marginTop: '12px',
        }}
      >
        <CheckInCard
          title={roomCode === '' ? 'Check-In' : 'Your Door Code'}
          content={
            roomCode === '' ? (
              <span className="reservation-date">
                {formatDateTime(reservation?.StartUtc || '')}
              </span>
            ) : (
              <h4 className="room-code">{`${roomCode}#`}</h4>
            )
          }
          active={!roomCode}
          data={guestPackages}
        />
        <hr
          style={{
            margin: 0,
            height: '1px',
            border: 'none',
            backgroundColor: '#979797',
            marginLeft: '15px',
          }}
        />
        <CheckInCard
          title="Check-out"
          content={
            <span className="reservation-date">
              {formatDateTime(reservation?.EndUtc || '')}
            </span>
          }
          type="checkOut"
          active={roomCode !== ''}
          checkOutFunction={openConfirmModal}
          titleCheckIn={roomCode ? 'Check-In' : ''}
          contentCheckIn={
            roomCode ? (
              <span className="reservation-date">
                {formatDateTime(reservation?.StartUtc || '')}
              </span>
            ) : (
              ''
            )
          }
        />
      </Container>

      <Container className="container-paddings">
        <Row style={{ marginTop: '24px' }}>
          <Col xs="6">
            <div className="table-title">
              <h6 className="mb-0" style={{ color: '#979797' }}>
                Customize Your Stay
              </h6>
            </div>
          </Col>
        </Row>

        {preregisteredGuest && guestPackages.length > 0 && (
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <Button onClick={() => setQuestionModal(true)} className="save-button">
                <Plus size={20} />
                Request Add-ons
              </Button>
            </Col>
          </Row>
        )}

        <div className="scroll-portal">
          <Row>
            {packages?.map(item => (
              <Col key={item.uuid} xs="4" lg="4" style={{ marginTop: '10px' }}>
                {guestPackages.find(
                  packageItem => packageItem?.packageId === item.uuid,
                ) && (
                  <Row>
                    <Col>
                      <Badge className="quantity-badge" bg="danger">
                        {guestPackages.find(
                          packageItem => packageItem?.packageId === item.uuid,
                        )?.quantity || ''}
                      </Badge>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'end' }}>
                      <OverlayTrigger
                        overlay={<Tooltip>Remove this package from the list</Tooltip>}
                      >
                        <Badge
                          className="notify-badge"
                          bg="danger"
                          onClick={() => deleteGuestPackage(item)}
                          style={{ cursor: 'pointer' }}
                        >
                          <Trash3Fill size={18} />
                        </Badge>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                )}
                <Container
                  onClick={() => showPackageModalHandler(item)}
                  className="package-container"
                >
                  {item?.media?.[0]?.uri ? (
                    <Image
                      alt=""
                      src={item?.media?.[0].uri}
                      className={
                        guestPackages.find(
                          packageItem => packageItem?.packageId === item.uuid,
                        )
                          ? 'd-inline-block align-top customize-stay-selected-container'
                          : 'd-inline-block align-top customize-stay-container'
                      }
                    />
                  ) : (
                    <Image
                      alt=""
                      src="/no_image.jpg"
                      className={
                        guestPackages.find(
                          packageItem => packageItem?.packageId === item.uuid,
                        )
                          ? 'd-inline-block align-top customize-stay-selected-container'
                          : 'd-inline-block align-top customize-stay-container'
                      }
                    />
                  )}
                  {item.name && item?.name?.length < 25 ? (
                    <h6 className="customize-stay-subtitle">{item.name}</h6>
                  ) : (
                    <OverlayTrigger overlay={<Tooltip>{item.name}</Tooltip>}>
                      <h6 className="customize-stay-subtitle">
                        {item?.name?.substring(0, maxTitleLength)} {' ...'}
                      </h6>
                    </OverlayTrigger>
                  )}
                </Container>
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      <Container className="container-paddings">
        <Row className="mt-16px">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>About Your Room</Accordion.Header>
              <Accordion.Body>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {reservation?.Room?.description ||
                    'We are working on a description about your room.'}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>View Check-In Steps</Accordion.Header>
              <Accordion.Body>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {reservation?.Room?.checkInSteps ||
                    'We are working to describe the check-in steps'}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Our Story</Accordion.Header>
              <Accordion.Body>
                <RenderHTMLContent
                  content={reservation?.Property?.ourStoryContent || ourStoryContent}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>

      {preregisteredGuest && preregisteredGuest.stripeId && (
        <CardForPayPackagesModal
          show={showAnotherCardModal}
          handleClose={() => {
            setShowAnotherCardModal(false);
            setQuestionModal(false);
          }}
          customerId={preregisteredGuest.stripeId}
          reservationId={reservationId || ''}
          onRequestSubmit={onSubmit}
        />
      )}

      {preregisteredGuest && preregisteredGuest.stripeId && (
        <PayPackagesQuestionModal
          show={questionModal}
          onConfirm={() => {
            setShowAnotherCardModal(true);
          }}
          onHide={() => {
            setQuestionModal(false);
            onSubmit();
          }}
          customerId={preregisteredGuest.stripeId}
        />
      )}

      <ConfirmModal
        show={confirmModal.show}
        title={confirmModal.title}
        body={confirmModal.body}
        handleCancel={closeConfirmModal}
        handleConfirm={handleConfirmModal}
      />
      {packageData && (
        <CustomizeStay
          show={customizeStayModal}
          handleClose={() => {
            setCustomizeStayModal(false);
          }}
          packageItem={packageData}
        />
      )}
    </FormProvider>
  );
};
