import { FC, useMemo } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import Container from 'react-bootstrap/Container';

import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  ICellRendererParams,
  ClientSideRowModelModule,
  ModuleRegistry,
  CellStyleModule,
  themeAlpine,
  PaginationModule,
  NumberEditorModule,
  TextEditorModule,
  TextFilterModule,
  NumberFilterModule,
} from 'ag-grid-community';
import { capitalCase } from 'case-anything';

import { CurrencyInput } from '../../Inputs/Currency';
import {
  statusColorMapper,
  statusTextColorMapper,
} from '../../../modules/mappers/statusColorMapper';
import './styles/styles.css';

export interface BaseGridProps<T = any> extends AgGridReactProps<T> {
  refreshHandler?: (token: string) => Promise<void>;
  searchString?: string;
  changeState: number;
  gridRef: React.RefObject<AgGridReact<T>>;
}

export const CurrencyCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  return <CurrencyInput displayType="text" value={value} />;
};

export const ImageLabelCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;
  return (
    <>
      <Image alt="" src={value.img} width="40" height="40" className="ag-cell-img" />
      <span>{value.name}</span>
    </>
  );
};

export const BadgeCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  return (
    <div
      className="status-badge"
      style={{
        backgroundColor: statusColorMapper[value.toUpperCase() as string],
        color: statusTextColorMapper[value.toUpperCase() as string],
      }}
    >
      {capitalCase(value)}
    </div>
  );
};

ModuleRegistry.registerModules([
  NumberEditorModule,
  TextEditorModule,
  TextFilterModule,
  NumberFilterModule,
  CellStyleModule,
  PaginationModule,
  ClientSideRowModelModule,
]);

export const TextWithTooltipCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value = '-' } = params;
  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <strong>{value}</strong>
        </Tooltip>
      }
    >
      <div>{value}</div>
    </OverlayTrigger>
  );
};

export const DataTable: FC<BaseGridProps> = <T,>(props: BaseGridProps<T>) => {
  const {
    rowData,
    refreshHandler,
    paginationPageSize = 1,
    searchString,
    gridRef,
    changeState,
    pagination,
    ...rest
  } = props;
  const memoizedData = useMemo(() => rowData, [rowData]);

  const rowHeight = 54;

  return (
    <Container fluid>
      <AgGridReact
        theme={themeAlpine}
        rowHeight={rowHeight}
        rowData={memoizedData}
        pagination={!!pagination}
        paginationPageSize={paginationPageSize}
        domLayout="autoHeight"
        {...rest}
      />
    </Container>
  );
};
