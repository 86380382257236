import { FC, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import './styles/styles.css';
import { useDeletePackage } from '../../Dashboards/Packages/packageEnpoints';
import { PackageContext } from '../../../modules/context/packageContext';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  packageId: string;
}

export const DeletePackageModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  packageId,
}) => {
  const { handleSubmit, reset, register, watch } = useForm({
    defaultValues: { option: 'no' },
  });

  const [{ data: deleteResult }, deletePackage] = useDeletePackage(packageId);
  const value = useContext(PackageContext);

  const onSubmit = () => {
    deletePackage();
  };

  useEffect(() => {
    if (!deleteResult || !value || value?.loading) return;

    const { refetch } = value;
    refetch();

    reset();
    handleClose();
  }, [deleteResult, value, reset, handleClose]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title className="editor-title">Deleting Add-on</Modal.Title>
        <span className="editor-sub-title">
          Are you sure you want to delete the add-on?
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="editor-title-label">Please confirm</Form.Label>
                <Form.Select className="editor-control" {...register('option')}>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="delete-button"
          disabled={watch('option') === 'no'}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
