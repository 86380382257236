import { FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';
import { formatCurrency } from '../../../modules/utils/formatCurrency';

type RevenueReportsTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
};

export const RevenueReportTable: FC<RevenueReportsTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
}: RevenueReportsTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [columns] = useState<ColDef<any>[]>([
    { field: 'period', headerName: 'PERIOD', flex: 1 },
    {
      field: 'total_rooms',
      headerName: 'ROOM REVENUE',
      flex: 1,
      sortable: true,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
    {
      field: 'total_addons',
      headerName: 'PACKAGE REVENUE',
      flex: 1,
      sortable: true,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },

    {
      field: 'total_revenue',
      headerName: 'TOTAL REVENUE',
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
    {
      field: 'taxes',
      headerName: 'TAXES',
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
    {
      field: 'total',
      headerName: 'TOTAL',
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
    />
  );
};
