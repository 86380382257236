import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser, CognitoIdToken } from 'amazon-cognito-identity-js';

import { AuthContext, CognitoCredential } from '../../../modules/context/authContext';
import { routes } from '../../../modules/mappers/urls';
import { AwsConfigAuth } from '../../../modules/config';
import { AuthStatus } from '../../../modules/enums/status';

Amplify.configure({ Auth: AwsConfigAuth });

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [credentialsInfo, setCredentialsInfo] = useState<CognitoCredential | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<CognitoUser | null>(null);
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.pending);

  const configureCredential = useCallback(
    (idToken: CognitoIdToken) => {
      setCredentialsInfo({
        token: idToken.getJwtToken(),
        email: idToken.payload.email,
        name: `${idToken.payload.given_name} ${idToken.payload.family_name}`,
      });
    },
    [setCredentialsInfo],
  );

  const handleSignOut = useCallback(async () => {
    await Auth.signOut();
    setUser(null);
    setCredentialsInfo(null);

    navigate(routes.LOGIN, {});
  }, [navigate, setCredentialsInfo]);

  const handleSignIn = useCallback(
    async (username: string, password: string) => {
      const cognitoUser = await Auth.signIn(username, password);
      const cognitoSession = await Auth.currentSession();
      const cognitoIdToken = cognitoSession.getIdToken();

      const origin = location.state?.from?.pathname
        ? `${location.state?.from?.pathname}${location.state?.from?.search || ''}`
        : routes.RESERVATIONS;

      configureCredential(cognitoIdToken);
      setUser(cognitoUser);
      setAuthStatus(AuthStatus.signedIn);
      navigate(origin);
    },
    [
      configureCredential,
      setUser,
      setAuthStatus,
      navigate,
      location.state?.from?.pathname,
    ],
  );

  const handleChangePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      if (authStatus === AuthStatus.signedIn) {
        await Auth.changePassword(user, oldPassword, newPassword);
      }
    },
    [authStatus, user],
  );

  const handleForgotPassword = useCallback(async (username: string) => {
    await Auth.forgotPassword(username);
  }, []);

  const handleForgotPasswordSubmit = useCallback(
    async (username: string, code: string, newPassword: string) => {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    },
    [],
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(result => {
        setUser(result);
        setAuthStatus(AuthStatus.signedIn);
        Auth.currentSession().then(sessionResult => {
          const idToken = sessionResult.getIdToken();
          configureCredential(idToken);
        });
      })
      .catch(() => {
        setUser(null);
        setAuthStatus(AuthStatus.signedOut);
      });
  }, [handleSignOut, setCredentialsInfo, configureCredential]);

  const value = useMemo(() => {
    return {
      authStatus,
      credentialsInfo,
      signIn: handleSignIn,
      signOut: handleSignOut,
      forgotPassword: handleForgotPassword,
      forgotPasswordSubmit: handleForgotPasswordSubmit,
      changePassword: handleChangePassword,
    };
  }, [
    authStatus,
    credentialsInfo,
    handleSignIn,
    handleSignOut,
    handleForgotPassword,
    handleForgotPasswordSubmit,
    handleChangePassword,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
