import { FC } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export const CurrencyInput: FC<NumericFormatProps> = (props: NumericFormatProps) => {
  return (
    <NumericFormat
      prefix="$"
      thousandSeparator=","
      decimalScale={2}
      fixedDecimalScale
      {...props}
    />
  );
};
