/* eslint-disable react/no-array-index-key */
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';

import { useForm, Controller } from 'react-hook-form';
import { Option } from 'react-multi-select-component';

import { NumericFormat } from 'react-number-format';

import { ErrorMessage } from '@hookform/error-message';
import { PackagesResponse } from '../../../modules/interfaces';
import { useAuth, useNotifications } from '../../../modules/hooks';

import {
  RANGE_OPTIONS,
  SIZE_OPTIONS,
  CATEGORIES_OPTIONS,
} from './constants/packageConstants';
import {
  useCreatePackage,
  useUpdatePackage,
} from '../../Dashboards/Packages/packageEnpoints';
import { PackageContext } from '../../../modules/context/packageContext';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { BasicFileUploader } from '../Basic/FileUploader';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  params?: Option[];
  values: PackagesResponse;
  isNew?: boolean;
}

interface SelectedOption {
  active: boolean;
  value: string;
  price?: number;
  quantity?: number;
}

interface FileStore {
  id?: string;
  url: string;
  file: File;
}

export const EarlyCheckInModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  params,
  values,
  isNew = true,
}) => {
  const [files, setFiles] = useState<FileStore[]>([]);
  const [fileLs, setFileList] = useState<FileList | null>(null);
  const [mediaDeleted, setMediaDeleted] = useState<string[]>([]);
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;

  const [paramsSelectedOptions, setParamsSelectedOptions] = useState<SelectedOption[]>(
    [],
  );
  const [resultState, setResultState] = useState<boolean>(false);
  const [packageParams, setPackageParams] = useState<Option[]>([]);
  const allPackages = useContext(PackageContext);

  const [
    { data: insertResult, error: insertError, loading: insertLoading },
    insertPackage,
  ] = useCreatePackage();

  const [
    { data: updateResult, error: updateError, loading: updateLoading },
    updatePackage,
  ] = useUpdatePackage(values?.uuid || '');
  const fileTypes = useMemo(() => ['JPEG', 'JPG', 'PNG', 'GIF'], []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<PackagesResponse>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      typePackages: 'PERMANENT',
      category: '',
      status: 'ACTIVE',
      params: [{ name: '', values: '', type: '' }],
      price: 0,
      initialDate: new Date(),
      endDate: new Date(),
    },
  });

  const handleCreatePackage = (formValues: PackagesResponse) => {
    const formData = new FormData();

    formData.append('name', formValues?.name || '');
    formData.append('description', formValues?.description || '');
    formData.append('typePackages', 'PERMANENT');
    formData.append('category', formValues?.category || '');
    formData.append('price', formValues?.price?.toString() || '0');
    formData.append('initialDate', '2024-01-26T22:14:47.189Z');
    formData.append('endDate', '2024-01-26T22:14:47.189Z');
    formData.append('params', JSON.stringify(formValues?.params || []));

    if (files) {
      const media = files
        .filter(file => file.file.name !== '' && file.file.size && file.file.type)
        .map(file => file.file);
      media.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    if (isNew) {
      insertPackage({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    } else {
      if (mediaDeleted.length > 0) {
        formData.append('mediaDeleted', JSON.stringify(mediaDeleted));
      }
      formData.append('status', 'ACTIVE');
      updatePackage({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }

    setResultState(true);
  };

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        const fileAdded = files.some(item => item.file.name === file.name);

        if (!fileAdded) {
          const fileUrl = URL.createObjectURL(file);
          setFiles([...files, { id: undefined, url: fileUrl, file }]);
        }
      });
    },
    [files],
  );

  const handleDeleteFile = useCallback(
    (fileUrl: string, uuid: string | undefined) => {
      const filteredFiles = files.filter(item => item.url !== fileUrl);
      const list = new DataTransfer();

      URL.revokeObjectURL(fileUrl);

      filteredFiles.forEach(fileStore => list.items.add(fileStore.file));

      const { files: dtFiles } = list;

      if (uuid) {
        setMediaDeleted([...mediaDeleted, uuid]);
      }

      setFileList(dtFiles.length ? dtFiles : null);
      setFiles(filteredFiles);
    },
    [files, mediaDeleted],
  );

  const onSubmit = (data: PackagesResponse) => {
    handleCreatePackage(data);
  };

  useEffect(() => {
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [insertError, setSimpleToasts, updateError]);

  useEffect(() => {
    if (!(insertResult || updateResult)) return;

    if (!resultState || !allPackages || insertLoading || updateLoading) return;

    const { refetch } = allPackages;
    refetch();
    setResultState(false);
    reset();
    handleClose();
  }, [
    updateResult,
    insertResult,
    resultState,
    handleClose,
    insertLoading,
    updateLoading,
    allPackages,
  ]);

  useEffect(() => {
    if (isNew) {
      setParamsSelectedOptions([]);
    }

    if (params) {
      reset({ name: values.name, description: values.description });
      setPackageParams(params);
      return;
    }
    const uniqueTypes =
      values?.params?.reduce((types: Option[], item) => {
        if (item.type && !types.find(itemType => itemType.value === item.type)) {
          types.push({ value: item.type, label: item.type });
          if ((item.type === 'SIZE' || item.type === 'RANGE') && item.quantity) {
            types.push({ value: 'QUANTITY', label: 'QUANTITY' });
          }
        }

        return types;
      }, []) || [];
    setPackageParams(uniqueTypes);

    const SizeRangeValue: SelectedOption[] = [];
    values?.params?.forEach(item => {
      if (item.type === 'SIZE' || item.type === 'RANGE') {
        SizeRangeValue.push({
          active: true,
          value: item.values,
          price: item.price,
          quantity: item.quantity,
        });
      } else {
        SizeRangeValue.push({
          active: true,
          value: item.values,
        });
      }
    });

    if (values?.media) {
      const mediaData = values?.media.map((item): FileStore => {
        return { id: item.uuid, url: item.uri, file: new File([], item?.name || '') };
      });
      setFiles(mediaData);
    }

    setParamsSelectedOptions(SizeRangeValue);

    reset({
      ...values,
    });
  }, [reset, values, params]);

  /* Functions to control the form data for the params types SIZE & RANGE */
  const sizeCheckHandler = (active: boolean, value: string, paramType: string) => {
    const paramsArray = getValues('params') || [];

    if (active) {
      setParamsSelectedOptions([...paramsSelectedOptions, { active, value }]);
      const paramsValues = {
        type: paramType,
        name: paramType,
        values: value,
        price: 0,
        quantity: 0,
      };
      setValue('params', [...paramsArray, paramsValues]);
    } else {
      const filteredArray = paramsArray.filter(item => item.values !== value);
      setParamsSelectedOptions(
        paramsSelectedOptions.filter(item => item.value !== value),
      );
      setValue('params', filteredArray);
    }
  };

  const setSizePrice = (
    paramValue: string,
    paramNumber: string,
    paramNumberType: string,
  ) => {
    const numericString = paramNumber.replace(/[^0-9.]/g, '');
    const floatValue = parseFloat(numericString);
    if (!paramNumber) return;

    const paramsArray = getValues('params') || [];
    const newArray = paramsArray.map(item => {
      if (item.values === paramValue) {
        return paramNumberType === 'price'
          ? { ...item, price: floatValue }
          : { ...item, quantity: floatValue };
      }
      return item;
    });

    setValue('params', newArray);
  };

  /* Functions to control the form data for the params types QUANTITY & ADVANCED NOTICE */
  const fieldParamHandler = (param: string) => {
    const paramsArray = getValues('params') || [];

    const paramsValues = {
      type: param,
      name: param,
      values: '',
    };
    setValue('params', [...paramsArray, paramsValues]);
  };

  const setParamValue = (paramType: string, paramValue: string) => {
    if (!paramValue) return;

    let paramsArray = getValues('params') || [];

    if (!paramsArray.find(item => item.type === paramType)) {
      fieldParamHandler(paramType);
      paramsArray = getValues('params') || [];
    }

    const newArray = paramsArray.map(item => {
      if (item.type === paramType) {
        return { ...item, values: paramValue };
      }
      return item;
    });

    setValue('params', newArray);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title className="editor-title">{values.name}</Modal.Title>
        <span className="editor-sub-title">
          {isNew ? 'Add new add-on' : 'Edit details'}
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Label className="editor-title-label">Name</Form.Label>
              <Form.Control
                className="editor-control "
                placeholder="Enter the add-on name..."
                {...register('name', { required: 'This is required.' })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                className="editor-control"
                placeholder="Enter a description..."
                {...register('description')}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Category </Form.Label>
              <Form.Select
                className="editor-control"
                placeholder="Select category"
                {...register('category', { required: 'This is required.' })}
              >
                {CATEGORIES_OPTIONS.map(value => {
                  return (
                    <option key={value.label} value={value.value}>
                      {value.label}
                    </option>
                  );
                })}
              </Form.Select>
              <ErrorMessage
                errors={errors}
                name="category"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>

          {packageParams?.find(item => item.value === 'ADVANCED NOTICE') && (
            <Row className="mt-16px">
              <Col>
                <Form.Label className="editor-title-label">Advanced Notice</Form.Label>
                <Form.Select
                  className="editor-control"
                  placeholder="Select days"
                  defaultValue={
                    values?.params?.find(item => item.type === 'ADVANCED NOTICE')
                      ?.values || ''
                  }
                  onChange={e => setParamValue('ADVANCED NOTICE', e.target.value)}
                >
                  {[...Array(10)].map((_, i) => {
                    return i + 1 === 1 ? (
                      <option key={1} value="1 day">
                        1 Day
                      </option>
                    ) : (
                      <option key={i + 1} value={`${i + 1} days`}>
                        {i + 1} Days
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          )}
          {!packageParams?.find(
            item => item.value === 'SIZE' || item.value === 'RANGE',
          ) && (
            <Row className="mt-16px">
              <Col>
                <Form.Label className="editor-title-label">Price</Form.Label>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <NumericFormat
                      value={value}
                      prefix="$ "
                      thousandSeparator
                      className="form-control editor-control"
                      placeholder="Enter the price..."
                      onValueChange={({ floatValue }) => {
                        onChange(floatValue);
                      }}
                    />
                  )}
                  name="price"
                  control={control}
                />
              </Col>
            </Row>
          )}
          {packageParams?.find(item => item.value === 'QUANTITY') &&
            !packageParams?.find(
              item => item.value === 'SIZE' || item.value === 'RANGE',
            ) && (
              <Row className="mt-16px">
                <Col>
                  <Form.Label className="editor-title-label">Quantity</Form.Label>

                  <Form.Control
                    className="editor-control"
                    placeholder="Select the quantity..."
                    defaultValue={
                      values?.params?.find(item => item.type === 'QUANTITY')?.values || ''
                    }
                    onFocus={() => {
                      fieldParamHandler('QUANTITY');
                    }}
                    onChange={e => setParamValue('QUANTITY', e.target.value)}
                  />
                </Col>
              </Row>
            )}

          {packageParams?.find(item => item.value === 'SIZE') && (
            <>
              <Row className="mt-16px">
                {SIZE_OPTIONS.map(value => (
                  <Col key={value.label}>
                    <Col sm={12}>
                      <Form.Label className="editor-title-label">Size</Form.Label>
                    </Col>
                    <Form.Check
                      label={value.label}
                      defaultChecked={
                        !!paramsSelectedOptions?.find(size => size.value === value.value)
                      }
                      onChange={e =>
                        sizeCheckHandler(e.currentTarget.checked, value.value, 'SIZE')
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row className="mt-16px">
                <Col sm={12}>
                  <Form.Label className="editor-title-label">Price</Form.Label>
                </Col>
                {SIZE_OPTIONS.map(itemSize => (
                  <Col key={itemSize.label}>
                    <NumericFormat
                      prefix="$ "
                      defaultValue={
                        paramsSelectedOptions?.find(size => size.value === itemSize.value)
                          ?.price || ''
                      }
                      thousandSeparator
                      className="form-control editor-control"
                      placeholder={`${itemSize.label} price`}
                      onChange={e =>
                        setSizePrice(itemSize.value, e.target.value, 'price')
                      }
                      disabled={
                        !paramsSelectedOptions?.find(
                          size => size.value === itemSize.value,
                        )
                      }
                    />
                  </Col>
                ))}
              </Row>
              {packageParams?.find(item => item.value === 'QUANTITY') && (
                <Row className="mt-16px">
                  <Col sm={12}>
                    <Form.Label className="editor-title-label">Quantity</Form.Label>
                  </Col>
                  {SIZE_OPTIONS.map(itemSize => (
                    <Col key={itemSize.label}>
                      <Form.Control
                        className="editor-control"
                        placeholder={`Select ${itemSize.label} Quantity...`}
                        defaultValue={
                          paramsSelectedOptions?.find(
                            range => range.value === itemSize.value,
                          )?.quantity || ''
                        }
                        onChange={e =>
                          setSizePrice(itemSize.value, e.target.value, 'quantity')
                        }
                        disabled={
                          !paramsSelectedOptions?.find(
                            quantity => quantity.value === itemSize.value,
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}

          {packageParams?.find(item => item.value === 'RANGE') && (
            <>
              <Row className="mt-16px">
                {RANGE_OPTIONS.map(value => (
                  <Col key={value.label}>
                    <Col sm={12}>
                      <Form.Label className="editor-title-label">Range</Form.Label>
                    </Col>
                    <Form.Check
                      label={value.label}
                      defaultChecked={
                        !!paramsSelectedOptions?.find(size => size.value === value.value)
                      }
                      onChange={e =>
                        sizeCheckHandler(e.currentTarget.checked, value.value, 'RANGE')
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row className="mt-16px">
                <Col sm={12}>
                  <Form.Label className="editor-title-label">Price</Form.Label>
                </Col>
                {RANGE_OPTIONS.map(itemRange => (
                  <Col key={itemRange.label}>
                    <NumericFormat
                      prefix="$ "
                      defaultValue={
                        paramsSelectedOptions?.find(
                          size => size.value === itemRange.value,
                        )?.price || ''
                      }
                      thousandSeparator
                      className="form-control editor-control"
                      placeholder={`${itemRange.label} price`}
                      onChange={e =>
                        setSizePrice(itemRange.value, e.target.value, 'price')
                      }
                      disabled={
                        !paramsSelectedOptions?.find(
                          size => size.value === itemRange.value,
                        )
                      }
                    />
                  </Col>
                ))}
              </Row>

              {packageParams?.find(item => item.value === 'QUANTITY') && (
                <Row className="mt-16px">
                  <Col sm={12}>
                    <Form.Label className="editor-title-label">Quantity</Form.Label>
                  </Col>
                  {RANGE_OPTIONS.map(itemRange => (
                    <Col key={itemRange.label}>
                      <Form.Control
                        className="editor-control"
                        placeholder={`Select ${itemRange.label} Quantity...`}
                        defaultValue={
                          paramsSelectedOptions?.find(
                            range => range.value === itemRange.value,
                          )?.quantity || ''
                        }
                        onChange={e =>
                          setSizePrice(itemRange.value, e.target.value, 'quantity')
                        }
                        disabled={
                          !paramsSelectedOptions?.find(
                            quantity => quantity.value === itemRange.value,
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}

          <Row className="mt-24px">
            <Form.Group className="mt-24">
              <Form.Label className="editor-title-label">Media Files</Form.Label>
              <Row>
                <BasicFileUploader
                  files={files}
                  fileLs={fileLs}
                  handleDeleteFile={handleDeleteFile}
                  handleFileChange={handleFileChange}
                />
              </Row>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '54px',
        }}
      >
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button"
          onClick={handleSubmit(onSubmit)}
          disabled={insertLoading || updateLoading}
        >
          {insertLoading || updateLoading ? 'Loading…' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
