import { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Coin } from 'react-bootstrap-icons';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../modules/config';
import { PaymentType } from '../../../modules/enums/types';

import './styles/styles.css';
import {
  useLoading,
  useNotifications,
  RequestOptions,
  useRequest,
  useGuestAuth,
  ErrorEnvelop,
} from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { GuestPortalErrors } from './constants/GuestPortalConstants';

interface PaymentIntent {
  clientSecret: string;
  paymentIntentId: string;
  chargeId?: string;
  customerId: string;
}

interface BaseCheckoutProps {
  handleModalClose?: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  handleCustomerId?: (customerId: string, clientSecret: string) => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
  roomTypeId: string;
  reservationId: string;
}

const stripePromise = loadStripe(config.stripe.publicKey);

const InnerForm: FC<BaseCheckoutProps> = ({ handleModalClose, isEnd }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setSimpleToasts } = useNotifications()!;
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setPaymentLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setSimpleToasts({ message: 'Stripe is not ready', type: 'danger', show: true });

      if (handleModalClose) {
        handleModalClose();
      }

      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      setSimpleToasts({
        message: error.message || 'Unable to capture payment method',
        type: 'danger',
        show: true,
      });
      setPaymentLoading(false);
    } else {
      setSimpleToasts({
        message: 'Payment added successfully',
        type: 'success',
        show: true,
      });
      setPaymentLoading(false);

      if (isEnd) isEnd(true);

      if (handleModalClose) {
        handleModalClose();
      }
    }
  };

  return (
    <>
      <Row>
        <PaymentElement />
      </Row>
      <Row
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          display: 'flex',
          justifyContent: 'center',
          marginTop: 38,
        }}
      >
        <Col xs="auto" sm="auto">
          <Button
            className="cancel-button no-border-radius"
            onClick={() => {
              if (handleModalClose) {
                handleModalClose();
              }
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col xs="auto" sm="auto">
          <Button
            onClick={e => handleSubmit(e)}
            className="save-button no-border-radius"
            disabled={!stripe || !elements || paymentLoading}
          >
            {paymentLoading ? 'Loading…' : 'Pay $100.00'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const SafetyDepositModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  isEnd,
  roomTypeId,
  reservationId,
  handleCustomerId,
}) => {
  const { token, generateAuthToken } = useGuestAuth()!;
  const { setSimpleToasts } = useNotifications()!;
  const { getValues } = useFormContext();
  const loadingHook = useLoading()!;
  const url = endpoints.CREATE_PAYMENT_INTENT;

  const requestOpts = useMemo<RequestOptions>(() => {
    const payload = {
      amount: 100,
      roomTypeId,
      guestInfo: {
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        email: getValues('email'),
        phone: getValues('phoneNumber'),
      },
      reservationId,
      paymentType: PaymentType.SAFETY_DEPOSIT,
    };

    return {
      authGuestToken: token,
      payload,
    };
  }, [roomTypeId, getValues, reservationId, token]);

  const [{ data, error, loading }, createPaymentIntent] = useRequest<PaymentIntent>(
    url,
    'post',
    requestOpts,
    { manual: true },
  );

  const [options, setOptions] = useState<StripeElementsOptions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (error) {
      setSimpleToasts({ type: 'danger', message: error.message, show: true });
    }

    if (data) {
      const { result } = data;

      if (handleCustomerId && result.customerId) {
        handleCustomerId(result.customerId, result.clientSecret);
      }
      setOptions({
        clientSecret: result.clientSecret,
        appearance: {
          theme: 'night',
          rules: {
            '.Input': {
              backgroundColor: '#0f0f0f',
              border: '0px',
            },
          },
        },
      });
    }
  }, [loading, error, data, loadingHook, setSimpleToasts]);

  useEffect(() => {
    if (token) {
      createPaymentIntent();
    }
  }, [token]);

  useEffect(() => {
    if (!error) return;

    if (
      getApiErrorMessage(error as AxiosError<ErrorEnvelop>) ===
      GuestPortalErrors.JWT_EXPIRED
    ) {
      setSimpleToasts({
        message: 'Token expired. Please wait while we refresh it. ',
        type: 'warning',
        show: true,
      });
      generateAuthToken({ data: { reservationId } });
    }
  }, [error]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header id="guest-portal" className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <Coin size={25} /> {'  '}
          Safety Deposit
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Spinner animation="border" variant="primary" className="d-block m-auto mt-4" />
      ) : (
        <Modal.Body className="guest-modal-header">
          {options ? (
            <Elements stripe={stripePromise} options={options}>
              <InnerForm handleModalClose={handleClose} isEnd={isEnd} />
            </Elements>
          ) : null}
        </Modal.Body>
      )}
    </Modal>
  );
};
