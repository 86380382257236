import { FC, useEffect, useState, useRef, useCallback, FormEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Col, Image, Row } from 'react-bootstrap';

import { DateTime } from 'luxon';
import { Archive, BoxArrowInLeft, PencilSquare, Trash } from 'react-bootstrap-icons';
import { DataTable, BadgeCellRenderer, TextWithTooltipCellRenderer } from '../DataTable';
import {
  useAuth,
  useRequest,
  useLoading,
  useNotifications,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { endpoints } from '../../../modules/mappers/urls';
import { config } from '../../../modules/config';
import { SearchInput } from '../../Inputs/Search';
import { DotDropDown } from '../../Customize/DropDown';
import { DropDownSelect } from '../../Inputs/DropDownSelect';
import { ArticlesDetails } from '../../Forms/Articles/Details';
import { PostEditorModal } from '../../Modals/PostEditor';
import { ConfirmModal, ModalState } from '../../Modals/Confirm';
import {
  Article,
  ArticleResponse,
  Categories,
  FilterRequest,
  OptionType,
  Users,
} from '../../../modules/interfaces';
import { ArticleStatus } from '../../../modules/enums/status';

import './styles/styles.css';

interface AdminArticleTableProps {
  changeState: number;
}

interface ArticleDotCellRendererProps {
  data?: Article;
  onClickEdit: (data?: Article) => void;
  onClickDelete: (data?: Article) => void;
  onClickArchive: (data?: Article) => void;
  onClickDraft: (data?: Article) => void;
}

export interface Media {
  name?: string;
  uri: string;
  thumbnailUri?: string;
  ext: string;
}

export interface Articles {
  name: string;
  body: string;
  status: ArticleStatus;
  tags: string[];
  createdBy: Users;
  media: Media[];
  categories: Categories;
}

const ArticleDotMenu: FC<ArticleDotCellRendererProps> = ({
  data,
  onClickEdit,
  onClickDelete,
  onClickArchive,
  onClickDraft,
}) => {
  return (
    <DotDropDown title="" drop="end">
      <Dropdown.Item
        onClick={() => {
          onClickEdit(data);
        }}
      >
        <PencilSquare className="dropdown-icon" />
        Edit Article
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickArchive(data);
        }}
      >
        <Archive className="dropdown-icon" />
        Move to Archive
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickDraft(data);
        }}
      >
        <BoxArrowInLeft className="dropdown-icon" />
        Move to Drafts
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickDelete(data);
        }}
      >
        <Trash className="dropdown-icon" />
        Delete Article
      </Dropdown.Item>
    </DotDropDown>
  );
};

const getDotMenu = ({
  onClickEdit,
  onClickArchive,
  onClickDelete,
  onClickDraft,
}: ArticleDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<Article>> = (
    params: ICellRendererParams<Article>,
  ) => {
    const { data } = params;
    return (
      <ArticleDotMenu
        data={data}
        onClickArchive={onClickArchive}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
        onClickDraft={onClickDraft}
      />
    );
  };

  return DotMenuCellRenderer;
};

export const AdminArticleTable: FC<AdminArticleTableProps> = ({ changeState }) => {
  const gridRef = useRef<AgGridReact>(null);
  const { setSimpleToasts } = useNotifications()!;
  const [tableData, setTableData] = useState<Article[]>([]);
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [editData, setEditData] = useState<Article>();
  const [updateUId, setUpdateUId] = useState<string>();
  const [filters, setFilters] = useState<FilterRequest>({
    name: '',
    categories: [],
    status: [{ id: ArticleStatus.PUBLISHED, name: ArticleStatus.PUBLISHED }],
  });
  const [confirmModal, setConfirmModal] = useState<ModalState>({
    show: false,
    title: '',
    body: '',
    handler: () => {
      // TODO: need to implement
    },
  });
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [
    { data: categoriesData, loading: categoriesLoading, error: categoriesError },
    refetchCategories,
  ] = useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
    authToken: credentialsInfo?.token,
  });

  const [{ data, loading, error }, refetch] = useRequest<ArticleResponse>(
    endpoints.ARTICLES,
    'get',
    {
      authToken: credentialsInfo?.token,
      params: {
        name: filters.name,
        categories: filters.categories.map(item => item.id).join(','),
        status: filters.status.map(item => item.name).join(','),
        currentPage: 0,
        pageCount: 0,
      },
    },
  );

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (data) {
      const { result } = data;
      setTableData(result.data);
    }
  }, [loading, data, error, setSimpleToasts, setLoading]);

  const [
    { loading: insertLoading, data: insertResult, error: insertError },
    insertArticles,
  ] = useRequest<string>(
    endpoints.ARTICLES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const updateUrl = `${endpoints.ARTICLES}/${updateUId}`;
  const [
    { loading: updateLoading, data: updateResult, error: updateError },
    updateArticles,
  ] = useRequest<string>(
    updateUrl,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const deleteUrl = `${endpoints.ARTICLES}/${updateUId}`;
  const [
    { loading: deleteLoading, data: deleteResult, error: deleteError },
    deleteArticle,
  ] = useRequest<string>(
    deleteUrl,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const patchUrl = `${endpoints.ARTICLES}/${updateUId}`;
  const [
    { loading: statusLoading, data: statusResult, error: statusError },
    patchArticle,
  ] = useRequest<string>(
    patchUrl,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const refreshHandler = useCallback(async () => {
    await refetch({ data: { filter: filters } });
  }, [refetch, filters]);

  const refreshCategories = useCallback(async () => {
    await refetchCategories();
  }, [refetchCategories]);

  useEffect(() => {
    setLoading(
      loading || insertLoading || deleteLoading || statusLoading || updateLoading,
    );
  }, [loading, insertLoading, deleteLoading, updateLoading, statusLoading, setLoading]);

  useEffect(() => {
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (statusError) {
      const message = getApiErrorMessage(statusError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (insertResult || deleteResult || statusResult || updateResult) {
      refetch();
    }
  }, [
    insertResult,
    deleteResult,
    statusResult,
    updateResult,
    insertError,
    deleteError,
    statusError,
    updateError,
    setSimpleToasts,
    setLoading,
    refetch,
  ]);

  useEffect(() => {
    refreshCategories();
  }, [changeState, refreshCategories]);

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts, setLoading]);

  const handleSubmitSuccess = useCallback(
    (
      articleTitle: string,
      articleBody: string,
      tagState: string[],
      categoryId: string,
      media: File[],
      mediaDeleted: string[],
    ) => {
      const formData = new FormData();

      formData.append('name', articleTitle);
      formData.append('body', articleBody);
      formData.append('tags', tagState.join(','));
      formData.append('category', categoryId);
      formData.append('mediaDeleted', mediaDeleted.join(','));

      media.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });

      if (updateUId !== '') {
        updateArticles({
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: credentialsInfo?.token,
          },
        });
      } else {
        insertArticles({
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: credentialsInfo?.token,
          },
        });
      }

      refreshHandler();
    },
    [credentialsInfo?.token, updateUId, refreshHandler, insertArticles, updateArticles],
  );

  const handleAddArticle = useCallback(() => {
    setUpdateUId('');
    setEditData(undefined);
    setEditModal(true);
  }, []);

  const closeConfirmModal = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
  };

  const handleDeleteArticle = useCallback(() => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
    deleteArticle();
  }, [confirmModal, setConfirmModal, deleteArticle]);

  const getCategoryDropDownData = useCallback(() => {
    const dropDownList: OptionType[] = [];
    categories.forEach(item => {
      dropDownList.push({
        id: item.uuid,
        name: item.name,
      });
    });
    return dropDownList;
  }, [categories]);

  const onCategoriesFilter = useCallback(
    (filter: OptionType[]) => {
      setFilters({
        ...filters,
        categories: filter,
      });
      refreshHandler();
    },
    [refreshHandler, filters],
  );

  const getStatusDropDownData = useCallback(() => {
    const dropDownList: OptionType[] = [];
    const articleStatus: ArticleStatus[] = [
      ArticleStatus.ARCHIVED,
      ArticleStatus.DRAFT,
      ArticleStatus.PUBLISHED,
    ];
    articleStatus.forEach(item => {
      dropDownList.push({
        id: item,
        name: item,
      });
    });
    return dropDownList;
  }, []);

  const onStatusFilter = useCallback(
    (filter: OptionType[]) => {
      setFilters({
        ...filters,
        status: filter,
      });
      refreshHandler();
    },
    [refreshHandler, filters],
  );

  const onArticleFilter = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const filterString = (e.currentTarget.children[1] as HTMLInputElement).value;
      setFilters({
        ...filters,
        name: filterString,
      });
      refreshHandler();
    },
    [refreshHandler, filters],
  );

  const onClickEdit = useCallback((article?: Article) => {
    if (article) {
      setUpdateUId(article.uuid);
      setEditData(article);
      setEditModal(true);
    }
  }, []);

  const onClickArchive = useCallback(
    (article?: Article) => {
      if (article) {
        patchArticle({
          url: `${endpoints.ARTICLES}/${article.uuid}`,
          data: { status: ArticleStatus.ARCHIVED },
        });
      }
    },
    [patchArticle],
  );

  const onClickDraft = useCallback(
    (article?: Article) => {
      if (article) {
        patchArticle({
          data: { status: ArticleStatus.DRAFT },
          url: `${endpoints.ARTICLES}/${article.uuid}`,
        });
      }
    },
    [patchArticle],
  );

  const onClickDelete = useCallback(
    (article?: Article) => {
      if (article) {
        setUpdateUId(article.uuid);
        setConfirmModal({
          show: true,
          title: 'Delete this category?',
          body: 'This action cannot be undone.',
          handler: handleDeleteArticle,
        });
      }
    },
    [handleDeleteArticle],
  );

  const [columns] = useState<ColDef<Article>[]>([
    { field: 'name', headerName: 'ARTICLE TITLE' },
    {
      valueGetter: params => {
        const { data: article } = params;
        const name = article
          ? `${article.categories.length > 0 ? article.categories[0].name : '-'}`
          : '-';
        return name;
      },
      headerName: 'ARTICLE CATEGORY',
    },
    {
      valueGetter: params => {
        const { data: article } = params;
        const name = article
          ? `${`${article.createdBy.firstName} ${article.createdBy.lastName}`}`
          : '-';
        return name;
      },
      headerName: 'AUTHOR',
    },
    {
      field: 'createdAt',
      valueGetter: params => {
        const { data: category } = params;
        let date: string | undefined = category?.createdAt;
        if (category?.createdAt !== undefined) {
          date = DateTime.fromISO(category.createdAt).toFormat('MM-dd-yyyy');
        }

        return date;
      },
      headerName: 'CREATION DATE',
      cellRenderer: TextWithTooltipCellRenderer,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      cellRenderer: BadgeCellRenderer,
    },
    {
      headerName: 'ACTION',
      colId: 'action',
      cellClass: 'actionCol justify-content-end',
      width: 100,
      cellRenderer: getDotMenu({
        onClickEdit,
        onClickArchive,
        onClickDraft,
        onClickDelete,
      }),
    },
  ]);

  return (
    <Row>
      <div className={editStatus ? 'd-none' : 'admin-article-table'}>
        <Row className="admin-article-table-search">
          <Col xl={6} md={4}>
            <label className="search-title-label">Search:</label>
            <SearchInput
              id="search-article"
              placeholder="Search Article"
              onSubmit={onArticleFilter}
            />
          </Col>
          <Col xl={3} md={4}>
            <label className="search-title-label">Status:</label>
            <DropDownSelect
              data={getStatusDropDownData()}
              placeholder="Select Status"
              onConfirm={onStatusFilter}
            />
          </Col>
          <Col xl={3} md={4}>
            <label className="search-title-label">Category:</label>
            <DropDownSelect
              data={getCategoryDropDownData()}
              placeholder="Select Category"
              onConfirm={onCategoriesFilter}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row className="d-flex align-items-center justify-content-between table-title-section">
              <Col sm={3}>
                <h5 className="table-title">All Articles</h5>
                <span className="table-sub-title">
                  Articles Amount: <span className="bold">{tableData.length}</span>
                </span>
              </Col>
              <Col sm={3} className="d-flex align-items-center justify-content-end">
                <Button
                  id="dropdown-basic-button"
                  className="w-auto table-add-button"
                  onClick={handleAddArticle}
                >
                  <Image alt="" src="/plus.svg" />
                  Add Article
                </Button>
              </Col>
            </Row>
            <DataTable
              refreshHandler={refreshHandler}
              rowData={tableData}
              gridRef={gridRef}
              columnDefs={columns}
              pagination
              changeState={changeState}
              paginationPageSize={config.tables.balances.paginationSize}
              onRowClicked={(e: RowClickedEvent<Article>) => {
                if (e.api.getFocusedCell()?.column.getColId() === 'action') {
                  return;
                }
                const { data: article } = e;
                if (article) {
                  setEditData(article);
                  setEditStatus(true);
                }
              }}
            />
          </Col>
        </Row>
      </div>
      {editStatus ? (
        <div className="admin-article-edit">
          <ArticlesDetails
            articleData={editData}
            handleClose={() => {
              setEditStatus(false);
            }}
            subpage
          />
        </div>
      ) : null}
      <PostEditorModal
        show={editModal}
        handleClose={() => {
          setEditModal(false);
        }}
        data={editData}
        categories={categories}
        handleSubmit={handleSubmitSuccess}
      />
      <ConfirmModal
        show={confirmModal.show}
        title={confirmModal.title}
        body={confirmModal.body}
        handleCancel={closeConfirmModal}
        handleConfirm={handleDeleteArticle}
      />
    </Row>
  );
};
