import { FC, useState, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { DataTable } from '../DataTable';
import { config } from '../../../modules/config';
import { Roles } from '../../../modules/interfaces';
import DeleteIcon from '../../../assets/delete-icon.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import VerticalDotMenuIcon from '../../../assets/vertical-dot-menu.svg';
import { roleTextColorMapper } from '../../../modules/mappers/statusColorMapper';

type RoleTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: Roles[];
  onEditRole: (role?: Roles) => void;
  onDeleteRole: (role?: Roles) => void;
};

export const RoleTable: FC<RoleTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  onEditRole,
  onDeleteRole,
}: RoleTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const renderMenuIcon = (params: RowClickedEvent<Roles>) => {
    return (
      <div className="menu-icon-container-style">
        <OverlayTrigger
          trigger="click"
          placement="left"
          rootClose
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <div
                  onClick={e => {
                    document.body.click();
                    onEditRole(params.data);
                  }}
                  style={{ paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={EditblackIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Edit</div>
                </div>
                <div
                  style={{ paddingTop: '8px' }}
                  className="edit-damage-report-container"
                  onClick={e => {
                    document.body.click();
                    onDeleteRole(params.data);
                  }}
                >
                  <img
                    className="edit-icon-image-style"
                    src={DeleteIcon}
                    alt="edit-black-icon"
                  />
                  <div className="delete-text-style">Delete</div>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div>
            <img src={VerticalDotMenuIcon} alt="vertical-icon" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const renderTagPermission = (params: RowClickedEvent<Roles>) => {
    const permissions = params.data?.permissions || [];
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {permissions.map((permission: string, index: number) => (
          <span key={permission} className="permission-tag">
            {permission}
          </span>
        ))}
      </div>
    );
  };

  const roleCellRenderer: FC<ICellRendererParams> = (params: ICellRendererParams) => {
    const { value, data } = params;
    return (
      <div
        className="role-text-view"
        style={{
          color: roleTextColorMapper[value as string],
        }}
      >
        {value}
      </div>
    );
  };

  const [columns] = useState<ColDef<Roles>[]>([
    { field: 'name', headerName: 'ROLE NAME', flex: 1, cellRenderer: roleCellRenderer },
    {
      cellRenderer: renderTagPermission,
      headerName: 'PERMISSIONS',
      flex: 2,
    },
    {
      field: 'createdAt',
      valueGetter: params => {
        const { data: role } = params;
        let date: string | undefined = role?.createdAt;
        if (role?.createdAt !== undefined) {
          date = DateTime.fromISO(role.createdAt).toFormat('yyyy-MM-dd HH:mm:ss');
        }
        return date;
      },
      cellStyle: { color: 'rgba(0, 0, 0, 0.5)' },
      headerName: 'Creation Date',
      flex: 1,
    },
    {
      cellRenderer: renderMenuIcon,
      flex: 0.3,
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
      pagination
      paginationPageSize={config.tables.reservations.paginationSize}
    />
  );
};
