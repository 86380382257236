import { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Button, Col, Row } from 'react-bootstrap';
import { Coin } from 'react-bootstrap-icons';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import LoadingOverlay from 'react-loading-overlay-ts';
import { config } from '../../../modules/config';

import './styles/styles.css';
import {
  useLoading,
  useNotifications,
  RequestOptions,
  useRequest,
  useAuth,
} from '../../../modules/hooks';
import { PaymentType } from '../../../modules/enums/types';
import { endpoints } from '../../../modules/mappers/urls';

import { PackageItem } from '../../../modules/interfaces';
import { useUpdateReservationPackage } from '../../Dashboards/Packages/packageEnpoints';

interface PaymentIntent {
  clientSecret: string;
  paymentIntentId: string;
  chargeId?: string;
}

interface BaseCheckoutProps {
  handleModalClose?: () => void;
  paymentSuccessHandler: (status: string, paymentStatus: string) => void;
  packageId?: string;
}

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  paymentSuccessHandler: (status: string, paymentStatus?: string) => void;

  packageData: PackageItem;
}

const stripePromise = loadStripe(config.stripe.publicKey);

const InnerForm: FC<BaseCheckoutProps> = ({
  handleModalClose,
  paymentSuccessHandler,
  packageId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setSimpleToasts } = useNotifications()!;
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentDeclined, setPaymentDeclined] = useState(false);
  const [
    { data: reservationPackageData, loading: loadingStatusUpdate },
    updateReservationPackageState,
  ] = useUpdateReservationPackage(packageId);
  const { credentialsInfo } = useAuth()!;

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setPaymentLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setSimpleToasts({ message: 'Stripe is not ready', type: 'danger', show: true });

      if (handleModalClose) {
        handleModalClose();
      }

      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      setSimpleToasts({
        message: error.message || 'Unable to capture payment method',
        type: 'danger',
        show: true,
      });
      setPaymentLoading(false);

      if (error.code !== 'invalid_number') {
        setPaymentDeclined(true);
        updateReservationPackageState({
          data: { status: 'FAILED' },
          headers: {
            authorization: credentialsInfo?.token,
          },
        });
      }
    } else {
      setSimpleToasts({
        message: 'Payment added successfully',
        type: 'success',
        show: true,
      });
      setPaymentLoading(false);
      updateReservationPackageState({
        data: { status: 'APPROVED' },
        headers: {
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  useEffect(() => {
    if (!reservationPackageData && !paymentDeclined) return;
    if (handleModalClose) {
      paymentSuccessHandler('APPROVED', 'PAID');
      handleModalClose();
    }

    if (handleModalClose && paymentDeclined) {
      paymentSuccessHandler('FAILED', 'Error');
      setPaymentDeclined(false);
      handleModalClose();
    }
  }, [reservationPackageData]);

  return (
    <>
      <Row>
        <PaymentElement />
      </Row>
      <Row
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          display: 'flex',
          justifyContent: 'center',
          marginTop: 38,
        }}
      >
        <Col xs="auto" sm="auto">
          <Button
            className="cancel-button no-border-radius"
            onClick={() => {
              if (handleModalClose) {
                handleModalClose();
              }
            }}
            disabled={loadingStatusUpdate}
          >
            Cancel
          </Button>
        </Col>
        <Col xs="auto" sm="auto">
          <Button
            onClick={e => handleSubmit(e)}
            className="save-button no-border-radius"
            disabled={!stripe || !elements || paymentLoading || loadingStatusUpdate}
          >
            {paymentLoading || loadingStatusUpdate ? 'Loading…' : 'Pay $100.00'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const PayWithAnotherCardModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  paymentSuccessHandler,
  packageData,
}) => {
  const { credentialsInfo } = useAuth()!;

  const { setSimpleToasts } = useNotifications()!;
  const loadingHook = useLoading()!;
  const url = endpoints.CREATE_PAYMENT_INTENT;

  const requestOpts = useMemo<RequestOptions>(() => {
    const payload = {
      amount: packageData.amount,
      roomTypeId: packageData.roomTypeId,
      customerId: packageData.customerId,
      reservationId: packageData.reservationId,
      paymentType: PaymentType.RESERVATION_PACKAGE,
      reservationPackageId: packageData.uuid,
      packageId: packageData.packageId,
    };

    return {
      authToken: credentialsInfo?.token,
      payload,
    };
  }, [
    credentialsInfo?.token,
    packageData.amount,
    packageData.customerId,
    packageData.packageId,
    packageData.reservationId,
    packageData.roomTypeId,
    packageData.uuid,
  ]);

  const [{ data, error, loading }] = useRequest<PaymentIntent>(url, 'post', requestOpts);

  const [options, setOptions] = useState<StripeElementsOptions>();

  useEffect(() => {
    if (loading) return;

    if (error) {
      setSimpleToasts({ type: 'danger', message: error.message, show: true });
    }

    if (data) {
      const { result } = data;
      setOptions({
        clientSecret: result.clientSecret,
      });
    }
  }, [loading, error, data, loadingHook, setSimpleToasts]);

  return (
    <LoadingOverlay active={loading} text="Wait while we get things ready.">
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="post-editor-modal modal-200w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="editor-title">
            <Coin size={25} /> Pay for requested add-on
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {options ? (
            <Elements stripe={stripePromise} options={options}>
              <InnerForm
                handleModalClose={handleClose}
                paymentSuccessHandler={paymentSuccessHandler}
                packageId={packageData.uuid}
              />
            </Elements>
          ) : null}
        </Modal.Body>
      </Modal>
    </LoadingOverlay>
  );
};
