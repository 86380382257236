import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Container, Row, Col, FormControl, Badge, Spinner } from 'react-bootstrap';

import {
  ArrowLeft,
  Whatsapp,
  ChatSquare,
  ArrowLeftCircleFill,
  Search,
} from 'react-bootstrap-icons';
import IconTint from 'react-icon-tint';
import { useLocation, useSearchParams } from 'react-router-dom';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { DateTime } from 'luxon';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';

import './styles/styles.css';
import CloseIcon from '../../../assets/close.svg';
import DetailsIcon from '../../../assets/details.svg';
import Emojicon from '../../../assets/emoji.svg';

import Send from '../../../assets/send.svg';
import UserIcon from '../../../assets/user.svg';
import {
  useAuth,
  useMessagingHook,
  MessageEvent,
  useRequest,
  useNotifications,
} from '../../../modules/hooks';
import {
  messagingEvents,
  MessageInboundData,
} from '../../../modules/mappers/messagingEvents';
import { generateUUID, getApiErrorMessage } from '../../../modules/utils/transform';
import { endpoints } from '../../../modules/mappers/urls';
import { config } from '../../../modules/config';
import { SearchInboxInput } from '../../Inputs/Search';
import BookingDetails from '../../BookingDetails';
import Header from '../../Header';
import { MewsReservation, MewsReservationResponse } from '../../../modules/interfaces';
import { GuestReservationTableModal } from '../../Modals/GuestReservationsModal';

type User = {
  id: string;
  name: string;
  lastMessage: string;
  lastMessageTimestamp: string;
  unread: boolean;
  reservationId: string;
  guestEmail: string;
};

type Message = {
  messageId: string;
  senderId: string;
  receiverId: string;
  text: string;
  timestamp: string;
  read: boolean;
  channel?: string;
  messageType: string;
  imageUrl?: string | undefined;
};

export interface ConversationSummaryData {
  guestPhoneNumber: string;
  lastMessageSender: string;
  lastMessageText: string;
  lastMessageTimestamp: string;
  propertyName: string;
  guestName: string;
  guestEmail: string;
  reservationId: string;
}

const transformInboundDataToMessage = (inboundData: MessageInboundData): Message => {
  return {
    messageId: inboundData.messageId,
    senderId: inboundData.sender,
    receiverId: inboundData.receiver,
    text: inboundData.text,
    timestamp: inboundData.timestamp,
    read: inboundData.status === 'read',
    channel: inboundData.channel,
    messageType: inboundData.messageType,
    imageUrl: inboundData.imageUrl,
  };
};

export interface SendMessageResponse {
  messageId: string;
  status: string;
}

type LoadedMessages = {
  [userId: string]: Message[];
};

export const InboxDashboard = () => {
  const location = useLocation();
  const { credentialsInfo } = useAuth()!;
  const [showInput, setShowInput] = useState(false);
  const [showConversation, setShowConversation] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { setSimpleToasts } = useNotifications()!;
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(users[0] || null);
  const [loadedMessages, setLoadedMessages] = useState<LoadedMessages>({});
  const [lastSentMessage, setLastSentMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSendFocus, setSendFocus] = useState<boolean>(false);
  const [isEmojiOpen, setEmojiOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedReservationId, setSelectedReservationId] = useState<string | null>('');
  const [selectedReservation, setSelectedReservation] = useState<MewsReservation | null>(
    null,
  );
  const [newMessage, setNewMessage] = useState<string>('');
  const [showSideNav, setShowSideNav] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const { selectedProperty, userPropertiesData, setSelectedProperty } =
    useContext(UserPropertiesContext)!;
  const [guestReservations, setGuestReservations] = useState<MewsReservation[]>([]);
  const [guestEmail, setGuestEmail] = useState<string>('');

  const [searchParams] = useSearchParams();

  const urlGuestEmail = searchParams.get('guestEmail');

  const urlPropertyId = searchParams.get('propertyId');

  const {
    guestReservationUser,
    reservationGuestId,
    reservationGuest,
    fromReservationDetails,
  } = location.state || {};

  const urlReservationsByEmail = `${endpoints.RESERVATION}/customer-reservations/${guestEmail}`;
  const [
    { loading: loadingReservationsByEmail, data: reservationsByEmail },
    getGuestsReservations,
  ] = useRequest<MewsReservationResponse>(
    urlReservationsByEmail,
    'get',
    {
      authToken: credentialsInfo?.token,
      propertyId: selectedProperty?.property.uuid,
    },
    { manual: true },
  );

  const filteredUsers = users.filter(user =>
    user.name?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleInboundMessage = useCallback(
    (message: MessageInboundData) => {
      const incomingMessage = transformInboundDataToMessage(message);
      setLoadedMessages(prevLoadedMessages => ({
        ...prevLoadedMessages,
        [incomingMessage.senderId]: [
          ...(prevLoadedMessages[incomingMessage.senderId] || []),
          incomingMessage,
        ],
      }));
      setUsers(prevUsers =>
        prevUsers.map(user => {
          if (
            user.id === incomingMessage.senderId &&
            (!selectedUser || user.id !== selectedUser.id)
          ) {
            return { ...user, unread: true };
          }
          return user;
        }),
      );
    },
    [selectedUser],
  );

  const eventHandlers: MessageEvent<MessageInboundData>[] = useMemo(
    () => [
      {
        name: messagingEvents.MESSAGE.INBOUND,
        callback: handleInboundMessage,
      },
    ],
    [handleInboundMessage],
  );

  useMessagingHook<MessageInboundData>(messagingEvents.CHANNEL_NAME, eventHandlers);

  const messageListRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const messageContainer = messageListRef.current;
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  };

  const propertyId = selectedProperty?.property?.uuid || '';
  const propertyPhoneNumber = config.messaging.property.TheCoachHouse;

  useEffect(() => {
    if (!urlPropertyId || !userPropertiesData?.userProperties) return;

    const property = userPropertiesData?.userProperties?.find(
      item => item.property.uuid === urlPropertyId.toString(),
    );

    if (property) {
      setSelectedProperty(property);
    }
  }, [urlPropertyId, userPropertiesData?.userProperties]);

  useEffect(() => {
    if (!guestEmail) return;

    getGuestsReservations();
  }, [guestEmail]);

  useEffect(() => {
    if (reservationGuestId) {
      setSelectedReservationId(reservationGuestId);
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const url = `${endpoints.MESSAGING}/send-message`;
  const [{ loading, data: messageSendResult, error }, sendMessagePost] =
    useRequest<SendMessageResponse>(
      url,
      'post',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [loading, messageSendResult, error, setSimpleToasts]);

  const fetchConversationUrl = `${endpoints.MESSAGING}/conversations/${propertyId}`;
  const [
    { loading: loadingConversations, data: conversationsData, error: conversationsError },
    fetchConversations,
  ] = useRequest(
    fetchConversationUrl,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const fetchConversationMessagesUrl = `${endpoints.MESSAGING}/conversations/${propertyId}/messages`;
  const [
    { loading: loadingMessages, data: messagesData, error: messagesError },
    fetchMessages,
  ] = useRequest(
    fetchConversationMessagesUrl,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const transformGetConversationData = (
    apiResponse: ConversationSummaryData[],
  ): User[] => {
    const newUsers: User[] = [];
    apiResponse.forEach((conversation: ConversationSummaryData) => {
      const user: User = {
        id: conversation.guestPhoneNumber,
        name: conversation.guestName,
        lastMessage: conversation.lastMessageText,
        lastMessageTimestamp: conversation.lastMessageTimestamp,
        reservationId: conversation.reservationId,
        guestEmail: conversation.guestEmail || '',
        unread: false,
      };

      newUsers.push(user);
    });
    return newUsers;
  };

  const transformGetMessagesData = (apiResponse: MessageInboundData[]): Message[] => {
    const newMessages: Message[] = [];
    apiResponse.forEach((messageData: MessageInboundData) => {
      const transformedMessage: Message = {
        messageId: messageData.messageId,
        senderId: messageData.sender,
        receiverId: messageData.receiver,
        text: messageData.text,
        timestamp: messageData.timestamp,
        read: messageData.status === 'read',
        channel: messageData.channel,
        messageType: messageData.messageType,
        imageUrl: messageData.imageUrl,
      };
      newMessages.push(transformedMessage);
    });
    return newMessages;
  };

  useEffect(() => {
    if (selectedProperty) {
      fetchConversations();
    }
  }, [fetchConversations, selectedProperty]);

  const handleSelectUser = async (user: User) => {
    setIsLoading(true);
    if (loadedMessages[user.id]) {
      setLoadedMessages(prevLoadedMessages => {
        const userMessages = prevLoadedMessages[user.id];
        let updatedMessages = userMessages.map(msg => ({ ...msg, read: true }));
        if (
          lastSentMessage &&
          !userMessages.find(msg => msg.messageId === lastSentMessage.messageId)
        ) {
          updatedMessages = [...updatedMessages, lastSentMessage];
        }
        return {
          ...prevLoadedMessages,
          [user.id]: updatedMessages,
        };
      });
    } else {
      await fetchMessages(`${fetchConversationMessagesUrl}/${user.id}`);
    }
    setGuestEmail(user.guestEmail);
    setSelectedUser(user);
    setShowConversation(true);
    setIsLoading(false);
    setUsers(prevUsers =>
      prevUsers.map(u => {
        if (u.id === user.id) {
          return { ...u, unread: false };
        }
        return u;
      }),
    );

    if (showSideNav) {
      setShowSideNav(false);
    }
    if (user.reservationId) {
      setSelectedReservationId(user.reservationId);
    } else {
      setSimpleToasts({
        type: 'danger',
        message: 'There is no reservation id associated with this conversation',
        show: true,
      });
    }
  };

  useEffect(() => {
    if (conversationsError) {
      const message = getApiErrorMessage(conversationsError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (conversationsData) {
      const newUsers = transformGetConversationData(
        conversationsData.result as ConversationSummaryData[],
      );

      setUsers(
        newUsers.sort((a, b) =>
          a.lastMessageTimestamp > b.lastMessageTimestamp ? -1 : 1,
        ),
      );

      if (guestReservationUser && guestReservationUser?.Email) {
        const user = newUsers.find(
          item => item.guestEmail === guestReservationUser?.Email.toString(),
        );
        if (user) {
          handleSelectUser(user);

          setSelectedReservationId(reservationGuestId);
          setSelectedReservation(reservationGuest);
        } else {
          sendMessagePost({
            data: {
              to: guestReservationUser?.Phone.replace('+', ''),
              text: 'Hello',
              messageType: 'text',
              timestamp: new Date().toISOString(),
              propertyId,
              imageUrl: '',
            },
          });
        }
      }

      if (urlGuestEmail) {
        const user = newUsers.find(item => item.guestEmail === urlGuestEmail.toString());

        if (user) {
          handleSelectUser(user);
        }
      }
    }
  }, [loadingConversations, conversationsData, conversationsError, setSimpleToasts]);

  useEffect(() => {
    if (!reservationsByEmail || loadingReservationsByEmail) return;

    if (reservationsByEmail.result.Reservations.length === 1) {
      setGuestReservations(reservationsByEmail.result.Reservations);
    } else {
      setGuestReservations(reservationsByEmail.result.Reservations);
    }
  }, [reservationsByEmail, loadingReservationsByEmail]);

  useEffect(() => {
    if (!selectedReservationId) return;

    if (reservationGuest && selectedReservationId === reservationGuestId) {
      setSelectedReservation(reservationGuest);
    }
  }, [selectedReservationId]);

  useEffect(() => {
    if (!reservationGuest) return;

    setSelectedReservation(reservationGuest);
  }, [reservationGuest, setSimpleToasts]);

  useEffect(() => {
    if (messagesError) {
      const message = getApiErrorMessage(messagesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (messagesData && selectedUser) {
      const messagesResult = messagesData.result as MessageInboundData[];
      const areMessagesForSelectedUser = messagesResult.some(
        message => message.guestPhoneNumber === selectedUser.id,
      );
      if (areMessagesForSelectedUser) {
        const newMessages: Message[] = transformGetMessagesData(
          messagesResult as MessageInboundData[],
        );

        setLoadedMessages(prevLoadedMessages => ({
          ...prevLoadedMessages,
          [selectedUser?.id || '']: newMessages,
        }));
      }
    }
  }, [loadingMessages, messagesData, messagesError, setSimpleToasts, selectedUser]);

  useEffect(() => {
    scrollToBottom();
  }, [loadedMessages, selectedUser]);

  const getLastMessageChannel = (userId: string): string => {
    const userMessages = loadedMessages[userId];
    if (userMessages && userMessages.length > 0) {
      return userMessages[userMessages.length - 1].channel || 'sms';
    }
    return 'sms'; // Return a default channel if no messages are found
  };

  const handleSendMessage = (): void => {
    if (newMessage.trim() && selectedUser) {
      const channelToUse = getLastMessageChannel(selectedUser.id);

      const newMsg: Message = {
        messageId: generateUUID(), // Temporary ID needed for UI
        senderId: propertyPhoneNumber,
        receiverId: selectedUser.id,
        text: newMessage,
        timestamp: new Date().toISOString(),
        read: true,
        messageType: 'text',
        channel: channelToUse,
      };
      setLastSentMessage(newMsg);
      setLoadedMessages(prevLoadedMessages => ({
        ...prevLoadedMessages,
        [selectedUser.id]: [...(prevLoadedMessages[selectedUser.id] || []), newMsg],
      }));

      setNewMessage('');
      sendMessagePost({
        data: {
          to: selectedUser.id,
          text: newMessage,
          messageType: 'text',
          timestamp: newMsg.timestamp,
          propertyId,
          imageUrl: '',
        },
      });
    }
  };

  const formatDateTime = (dateString: string) => {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL);
  };

  const formatMessageDateTime = (timestamp: string): string => {
    const messageDateTime = DateTime.fromISO(timestamp);
    const now = DateTime.now();

    if (messageDateTime.hasSame(now, 'day')) {
      return messageDateTime.toLocaleString(DateTime.TIME_SIMPLE);
    }
    return messageDateTime.toFormat('LLL dd, yyyy');
  };

  const formatMessageTime = (timestamp: string): string => {
    const messageDateTime = DateTime.fromISO(timestamp);
    return messageDateTime.toFormat('hh:mm a');
  };

  const showChannelIcon = (channel: string | undefined) => {
    if (channel === 'sms') {
      return <ChatSquare size={12} />;
    }
    return <Whatsapp size={12} />;
  };

  const handleMouseEnterSend = () => {
    setSendFocus(true);
  };

  const handleMouseLeaveSend = () => {
    setSendFocus(false);
  };

  const handleBookingDetailsData = (item: MewsReservation) => {
    setSelectedReservation(item);
    setShowSideNav(true);
  };
  const handleBookingDetailsSidebar = () => {
    if (fromReservationDetails) {
      setSelectedReservation(reservationGuest);
    } else {
      setSelectedReservation(guestReservations[0]);
    }

    setShowSideNav(true);
  };

  const onEmojiClick = useCallback(() => {
    setEmojiOpen(!isEmojiOpen);
  }, [isEmojiOpen]);

  const onEmojiSelectClick = useCallback(
    (emoji: EmojiClickData) => {
      setNewMessage(newMessage + emoji.emoji);
    },
    [newMessage],
  );

  return (
    <Container id="inbox" fluid className="mainView">
      <Header title="Inbox" />
      <Container fluid>
        <Row className="flex-grow-1 overflow-hidden table-message-container">
          {/* Always render user list on non-mobile, conditionally render on mobile */}
          {(isMobile && !showConversation) || !isMobile ? (
            <Col
              xs={12}
              md={3}
              lg={3}
              className="p-0 d-flex flex-column conversations-container"
            >
              <div
                style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
              >
                {!showInput ? (
                  <Search
                    size={20}
                    style={{
                      cursor: 'pointer',
                      marginBottom: '20px',
                      margin: '4%',
                      marginLeft: '25px',
                    }}
                    onClick={() => setShowInput(true)}
                  />
                ) : (
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      transition: 'opacity 0.3s ease, transform 0.3s ease',
                      opacity: showInput ? 1 : 0,
                      transform: showInput ? 'translateX(0)' : 'translateX(-10px)',
                    }}
                  >
                    <SearchInboxInput
                      style={{
                        width: '92%',
                        marginBottom: '20px',
                        margin: '4%',
                        transition: 'all 0.3s ease',
                      }}
                      placeholder="Search guests here"
                      onBlur={() => setShowInput(false)}
                      onChange={e =>
                        setSearchTerm(
                          (e.currentTarget.children[1] as HTMLInputElement).value,
                        )
                      }
                    />
                  </div>
                )}
              </div>

              <div className="conversation-list-container overflow-auto">
                {filteredUsers.map(user => (
                  <div key={user.id}>
                    <div
                      onClick={() => {
                        handleSelectUser(user);
                        if (isMobile) setShowConversation(true);
                      }}
                      className={`d-block user-select-button w-100 text-start ${
                        selectedUser && selectedUser.id === user.id
                          ? 'btn-grey'
                          : 'btn-transparent'
                      }`}
                    >
                      <div className="user-item">
                        {selectedUser && selectedUser.id === user.id ? (
                          <div className="select-border" />
                        ) : (
                          <div className="un-select-border" />
                        )}
                        <img className="user_image" alt="user" src={UserIcon} />
                        <div className="user-details">
                          <div className="user-name">{user.name}</div>
                        </div>
                        <div className="user-date">
                          <div className="room-id">
                            {formatMessageDateTime(user.lastMessageTimestamp)}
                          </div>
                          {user.unread && (
                            <Badge className="badge-unread-message" pill>
                              1
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          ) : null}
          {/* Conditionally render conversation on mobile, always render on non-mobile */}

          {guestReservations.length !== 0 && (
            <GuestReservationTableModal
              selectedReservations={guestReservations}
              show={showBookingModal}
              handleClose={() => setShowBookingModal(false)}
              setConfirmedReservation={handleBookingDetailsData}
            />
          )}

          <Col
            xs={12}
            md={showSideNav && !isMobile ? 5 : 9}
            lg={showSideNav && !isMobile ? 5 : 9}
            className="p-0 d-flex flex-column messages-container"
          >
            <Col className="p-0 messages-title">
              <div className="header-chat">
                {isMobile && showConversation && (
                  <ArrowLeft
                    style={{ color: 'black', marginRight: '8px', cursor: 'click' }}
                    onClick={() => setShowConversation(false)}
                  />
                )}
                {selectedUser && <img className="user_image" alt="user" src={UserIcon} />}
                <div className="header-chat-title">
                  {selectedUser && selectedUser.name}
                </div>
              </div>
              {!showBookingModal &&
                selectedReservationId &&
                (loadingReservationsByEmail ? (
                  <div className="sidebar-button mr-8px">
                    <Spinner
                      animation="border"
                      variant="primary"
                      style={{ color: '#2BD579', marginRight: '10px' }}
                    />
                  </div>
                ) : (
                  <div
                    className="sidebar-button mr-8px"
                    onClick={() =>
                      guestReservations.length <= 1 || fromReservationDetails
                        ? handleBookingDetailsSidebar()
                        : setShowBookingModal(!showBookingModal)
                    }
                  >
                    <img alt="details " src={DetailsIcon} />
                    {!isMobile && (
                      <div className="booking-details-title">Booking details</div>
                    )}
                  </div>
                ))}
            </Col>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                className="d-block m-auto mt-4"
                style={{ color: '#2BD579' }}
              />
            ) : (
              <div
                ref={messageListRef}
                className={selectedUser ? 'message-list-container   p-3' : 'no-data-list'}
              >
                {selectedUser ? (
                  loadedMessages[selectedUser.id]?.map(message => (
                    <div
                      key={message.messageId}
                      className={`d-flex mb-2 position-relative ${
                        message.senderId === propertyPhoneNumber
                          ? 'justify-content-end'
                          : ''
                      }`}
                    >
                      {message.senderId === propertyPhoneNumber ? (
                        <div className="msg-text" style={{ marginRight: 5 }}>
                          {formatMessageTime(message.timestamp)}
                        </div>
                      ) : (
                        <div className="inbound-corner" />
                      )}
                      <div
                        className={`message-bubble ${
                          message.senderId === propertyPhoneNumber
                            ? 'outbound'
                            : 'inbound'
                        }`}
                      >
                        {message.messageType === 'text' && (
                          <div className="message-title">{message.text}</div>
                        )}
                        {message.messageType === 'image' && message.imageUrl && (
                          <img
                            src={message.imageUrl}
                            alt="Message attachment"
                            style={{ maxWidth: '100%' }}
                          />
                        )}
                        <div className="message-channel">
                          {/* {showChannelIcon(message.channel)} */}
                        </div>
                      </div>
                      {message.senderId !== propertyPhoneNumber ? (
                        <div className="msg-text" style={{ marginLeft: 5 }}>
                          {formatMessageTime(message.timestamp)}
                        </div>
                      ) : (
                        <div className="outboud-corner" />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="message-empty-message">
                    Select a conversation from the list on the left
                  </div>
                )}
              </div>
            )}
            <div
              className="bottom-send-container"
              style={{ marginBottom: !isMobile ? '8px' : '0px' }}
            >
              <FormControl
                as="textarea"
                rows={2}
                placeholder="Type a message…"
                className="send-input-container"
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
              />

              <div className="bottom-input-container">
                <div
                  className="fee-expand-btn"
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                  onClick={onEmojiClick}
                >
                  <img alt="emoji" src={Emojicon} />
                </div>

                <div
                  onClick={handleSendMessage}
                  onMouseEnter={handleMouseEnterSend}
                  onMouseLeave={handleMouseLeaveSend}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <IconTint color={isSendFocus ? '#222222' : '#b3b3b3'} src={Send} />
                </div>
              </div>
            </div>

            {isEmojiOpen && (
              <EmojiPicker
                searchDisabled
                width="100%"
                style={{
                  borderRadius: '0px',

                  minHeight: '250px',
                  maxHeight: '250px',
                }}
                previewConfig={{ showPreview: false }}
                onEmojiClick={onEmojiSelectClick}
                className="emoji-picker"
              />
            )}
          </Col>

          {!isMobile && (
            <Col
              md={4}
              lg={4}
              className={`border-left reservation-container ${
                showSideNav ? 'show-side-nav' : 'hide-side-nav'
              }`}
            >
              <div className="sidebar-title">
                <div
                  className="sidebar-button"
                  onClick={() => setShowSideNav(!showSideNav)}
                >
                  <ArrowLeftCircleFill size={25} style={{ color: 'black' }} />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => setShowSideNav(false)}>
                  <img alt="details" src={CloseIcon} />
                </div>
              </div>

              {loadingReservationsByEmail || loadingConversations ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="d-block m-auto mt-4"
                />
              ) : (
                selectedReservation && (
                  <BookingDetails
                    selectedReservation={selectedReservation}
                    fromReservationDetails={!!fromReservationDetails}
                  />
                )
              )}
            </Col>
          )}
          {isMobile && (
            <div
              className={`border-left reservation-container float ${
                showSideNav ? 'show-side-nav' : 'hide-side-nav'
              }`}
            >
              <div className="sidebar-title">
                <div
                  className="sidebar-button"
                  onClick={() => setShowSideNav(!showSideNav)}
                >
                  <ArrowLeftCircleFill size={25} style={{ color: 'black' }} />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => setShowSideNav(false)}>
                  <img alt="details" src={CloseIcon} />
                </div>
              </div>

              {loadingReservationsByEmail || loadingConversations ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="d-block m-auto mt-4"
                />
              ) : (
                selectedReservation && (
                  <BookingDetails
                    selectedReservation={selectedReservation}
                    fromReservationDetails={!!fromReservationDetails}
                  />
                )
              )}
            </div>
          )}
        </Row>
      </Container>
    </Container>
  );
};
