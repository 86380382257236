import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Container, Overlay, Popover } from 'react-bootstrap';

import '../styles/styles.css';
import PropertyIcon from '../../../assets/property.svg';
import PropertyList from '../../../assets/property-list.svg';
import PropertyListSelect from '../../../assets/property-list-select.svg';
import PropertySelect from '../../../assets/property-select.svg';
import { SearchInboxInput } from '../../Inputs/Search';
import { UserProperties } from '../../../modules/interfaces';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';

interface PropertyProps {
  expand: boolean;
}

const Property: FunctionComponent<PropertyProps> = ({ expand }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [propertyList, setPropertyList] = useState<UserProperties[]>([]);

  const [property, setProperty] = useState<UserProperties>();

  const {
    userPropertiesData,
    setSelectedProperty,
    setIsValidSelectedProperty,
    selectedProperty,
  } = useContext(UserPropertiesContext)!;

  const onPropertySelect = (propertyItem: UserProperties) => {
    if (propertyItem) {
      setSelectedProperty(propertyItem);

      setIsValidSelectedProperty(true);
    }

    setShow(false);
  };

  useEffect(() => {
    if (!selectedProperty) return;
    setProperty(selectedProperty);
  }, [selectedProperty]);

  useEffect(() => {
    if (!userPropertiesData?.userProperties) return;

    const userPropertyList = userPropertiesData.userProperties;
    const defaultProperty = userPropertyList.find(
      userProperty => userProperty.default === true,
    );

    setPropertyList(userPropertyList);

    if (defaultProperty) {
      setIsValidSelectedProperty(true);
      setSelectedProperty(defaultProperty);
    } else {
      setIsValidSelectedProperty(false);
    }
  }, [userPropertiesData]);

  const renderPropertyItem = (propertyItem: UserProperties) => {
    const isSelect = property?.property?.name === propertyItem?.property?.name;
    return (
      <div
        key={propertyItem.uuid}
        onClick={() => onPropertySelect(propertyItem)}
        className={isSelect ? 'property_list_item_select' : 'property_list_item'}
      >
        <img alt="property" src={isSelect ? PropertyListSelect : PropertyList} />
        <div
          className="property_list_title"
          style={{ color: isSelect ? '#15CF5F' : '#000000' }}
        >
          {propertyItem.property.name}
        </div>
      </div>
    );
  };

  return (
    <>
      <Container
        ref={target}
        onClick={() => setShow(!show)}
        className="property_container"
        style={{ width: expand ? '90%' : '48px', marginBottom: expand ? '10px' : '21px' }}
      >
        <img alt="property" src={PropertyIcon} />
        {expand && (
          <div className="property_details_container">
            <div className="property_text">Property</div>
            <div className="property__name_text">{property?.property.name}</div>
          </div>
        )}
        {expand && <img alt="property_select" src={PropertySelect} />}
      </Container>
      <Overlay
        onHide={() => setShow(false)}
        target={target.current}
        rootClose
        show={show}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="search_property_container">
              <SearchInboxInput
                style={{ width: '240px', marginRight: '15px' }}
                placeholder="Search property here"
              />
            </div>
            {propertyList.map(item => {
              return renderPropertyItem(item);
            })}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default Property;
